<template lang="pug">
.all
  BorderGradient(noPadding=true).all
    .instructions
      .container 
        .box
          h3 {{ upperCase($t("informationProfile.title")) }}
          br
          span {{$t("informationProfile.message")}}
          
          br
          br
          form
            input(type="email" name="email" id="email" :style="{ backgroundColor: invalid(this.form.email) ? 'rgb(255, 192, 203)' : '' }" v-model="form.email" :placeholder="`${$t('informationProfile.form.email')}`")
            br
            br
            input(type="number" name="length" id="length" :style="{ backgroundColor: invalid(this.form.length) ? 'rgb(255, 192, 203)' : '' }" v-model="form.length" :placeholder="`${$t('informationProfile.form.length')}`")
            br
            br
            input(type="number" name="weight" id="weight" :style="{ backgroundColor: invalid(this.form.weight) ? 'rgb(255, 192, 203)' : '' }" v-model="form.weight" :placeholder="`${$t('informationProfile.form.weight')}`")
            br
            br

            //- v-radio-group(v-model="selected") 
            //-   p Source vidéo :
            //-   v-radio(v-for="(source, index) in sourcesList" class="theme--dark" :key="index" :label="source" :value="source" :color="colors.magenta")

            p.radio {{$t("informationProfile.form.gender")}}
            .radio
              input(name="sexe" type="radio" @click="form.sex='H'" id="H")
              span {{$t("informationProfile.form.man")}}
            br
            .radio
              input(name="sexe" type="radio" @click="form.sex='F'" id="F")
              span {{$t("informationProfile.form.wife")}}
            br
            .radio
              input(name="sexe" type="radio" @click="form.sex='A'" id="A" checked)
              span {{$t("informationProfile.form.other")}}

          br
          .btn-group 
            v-btn(@click="send()") {{$t("informationProfile.next")}}

</template>



<script>
import Vuex from "vuex";
import BorderGradient from "@/components/_design/BorderGradient.component";


export default {
  name: "informationProfil",

  props: ['upperCase'],

  components : {
    BorderGradient,
  },
  data: () => ({
    form: {
      length:'',
      weight: '',
      email: 'email@utilisateur.fr',
      sex: 'A',
    },


    // sourcesList: ['test1', 'test2', 'test3'],
    // selected: 'test2',

    submitted: false,

    colors: {
      bad: '#E84B65',
      good: '#00F6A8',
      magenta: '#ff00ff',
      black: '#000000',
      blackalt: '#010220',
      white: '#FFFFFF',
    },
  }),

  methods: {
    ...Vuex.mapActions({
      patch: "users/profils/patch",
    }),
    send() {
      if (!this.emptyForm) {
        console.log(this.validLength())
        console.log(this.validWeight())
        if (this.validLength() && this.validWeight()) {
          this.next()
        } else {
          alert('Attention à bien saisir une taille correcte et un poids correct !')
        }
      } else {
        alert('Tous les champs doivent être remplis !')
      }
      this.submitted = true
    },
    next() {
      this.$emit('next-event')
    },

    validLength() {
      return parseFloat(this.form.length) > 100 && parseFloat(this.form.length) < 250
    },

    validWeight() {
      return parseFloat(this.form.weight) > 30 && parseFloat(this.form.weight) < 150
    },

    invalid(champ) {
      return this.submitted && champ === ''
    }
  },

  computed: {
    emptyForm() {
      return this.form.email === '' || this.form.length === '' || this.form.weight === ''
    }
  }

}


</script>



<style lang="scss" scoped>

$magenta: #ff00ff;
$bad: #E84B65;
$good: #00F6A8;
$black: #000000;
$blackalt: #010220;
$white: #FFFFFF;

@mixin indicator {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

@mixin timer {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

@mixin timervariant {
  font-style: normal;
  font-weight: 500;
  font-size: 96px;
  line-height: 144px;
}

input {
  width: 70%;
  padding: 10px;
  border-radius: 10px;
  background-color: $white;
  @media (max-width: 400px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 85%;
  }
}

input:focus {
  outline: none;
}

input[type=radio] {
  width: 25px;
  height: 25px; 
  outline: none;
  margin-right: 10px;
}

.isEmpty {
  border-color: $bad;
}

.radio {
  display: flex;
  width: 70%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



input[type="radio"]:checked:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
  background-color: magenta;
}

.instructions {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: $blackalt;
  color: $white;

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 600px) {
      width: 60%;
    }

    @media (min-width: 900px) {
      width: 30%;
    }

    @media (max-width: 599px) {
      width: 80%;
    }

    .box {
      text-align: center;

      .link {
        color: $magenta;
        display: inline-block;
      }

      .btn-group {
        display: flex;
        justify-content: center;
        gap: 10px;

      }

    }
  }
}
</style>
