import 'es6-promise/auto'
import Vue from 'vue'
import Vuex from 'vuex'
// import VuexPersistence from 'vuex-persist'
// import localForage from 'localforage'

import clothes from '@/plugins/store/modules/clothes';
import scenes from '@/plugins/store/modules/scenes';
import framings from '@/plugins/store/modules/framings';
import states from '@/plugins/store/modules/states';
import users from '@/plugins/store/modules/users';
import communication from '@/plugins/store/modules/communication';
import cmdRemotePeer from '@/plugins/store/modules/cmdRemotePeer';

import UsersService from "@/services/users.service";
import ApiService from '@/services/api.service'

Vue.use(Vuex)

// const vuexLocal = new VuexPersistence({
//   storage: localForage,
//   asyncStorage: true
// })

// console.log(vuexLocal)

const state = {
  configApi: {
    url: 'localhost',
    port: 8000
  },
  globalError: null,
  loading: false,
  dialog: false,
  // payer: null,
  // initiator: null
  initData: null,
  payer: null,
  initiator: null,
  fromShop: false,
  ShopName: "",
  productList: null,
  shopCustomer: null,
  // ToShopNameProducts:[],

  CurrentWorkingClothStore: null,
  ShoppingList: [],
};

const getters = {
  api: (state) => state.configApi,
  isDialog: (state) => state.dialog,
  globalError: (state) => state.globalError,
  initData: (state) => state.initData,
  payer: (state) => state.payer,
  initiator: (state) => state.initiator,

};

const mutations = {
  INITIALISATION: (state, init) => { state.initData = init },
  CHECKOUT_SUCCESS: (state, success) => { state.globalError = success; },
  CHECKOUT_ERROR: (state, error) => { state.globalError = error; },
  TRIGGER_LOADING: (state) => { state.loading = !state.loading; },
  TRIGGER_DIALOG: (state) => { state.dialog = !state.dialog; },
  SET_PAYER_USER: (state, user) => { state.payer = user; localStorage.setItem('userPayerId', user.id) },
  SET_INITIATOR_USER: (state, user) => { state.initiator = user; localStorage.setItem('userInitiatorId', user.id) },
  setProductList(state, productList) {
    state.productList = JSON.parse(productList);
  },
  setShopCustomert(state, shopCustomer) {
    state.shopCustomer = JSON.parse(shopCustomer);
  },  
  setFromShop(state, fromShop) {
    state.fromShop = fromShop;
  },
  setShopName(state, ShopName) {
    state.ShopName = ShopName;
  },

  setCurrentWorkingClothStore(state, CurrentWorkingClothStore) {
    state.CurrentWorkingClothStore = CurrentWorkingClothStore;
  },

  // FOR SHOPPING LIST ShoppingList
  ADD_TO_LIST(state, item) {
    state.ShoppingList.push(item);
  },
  DELETE_FROM_LIST(state, item) {
    const index = state.ShoppingList.findIndex(listItem => listItem.barCode === item.barCode);
    if (index > -1) {
      state.ShoppingList.splice(index, 1);
    }
  },
  UPDATE_IN_LIST(state, item) {
    const index = state.ShoppingList.findIndex(listItem => listItem.barCode === item.barCode);
    if (index > -1) {
      state.ShoppingList[index] = item;
    }
  },
  
};

const actions = {
  resetCheckout: (state) => {
    state.commit('CHECKOUT_SUCCESS', null);
    state.commit('CHECKOUT_ERROR', null);
  },
  triggerDialog(state) {
    console.log('TRIGGER_DIALOG');
    state.commit('TRIGGER_DIALOG');
  },
  // initialisation: async (state, token) => {
  //   localStorage.setItem('accessToken', token)
  //   const as = new ApiService()
  //   const payload = await as.getPayloadToken(token)
  //   state.commit('INITIALISATION', payload)
  //   // state.commit('SET_PAYER_USER', state.dispatch('isUserExist', payload.payer.id) ? { id: payload.payer.id } : null)
  //   // state.commit('SET_INITIATOR_USER', state.dispatch('isUserExist', payload.initiator.id) ? { id: payload.initiator.id } : null)
  // },
  initialisation: async (state, token) => {
    localStorage.setItem('accessToken', token)
    localStorage.setItem('userToken', token)
    const as = new ApiService()
    console.log("==========token=========",token)
    const payload = await as.getPayloadToken(token)
    console.log("==========payload=========",payload)
    state.commit('INITIALISATION', payload)
    console.log("==========payload.id=========",payload.id)
    localStorage.setItem('userEmail', payload.email)
    state.commit('SET_PAYER_USER', state.dispatch('isUserExist', payload.payer.id) ? { id: payload.payer.id } : null)
    state.commit('SET_INITIATOR_USER', state.dispatch('isUserExist', payload.initiator.id) ? { id: payload.initiator.id } : null)
  },

  isUserExist: async (state, idUser) => {
    const us = new UsersService()
    return await us.isExist(idUser)
  },

  setPayer: async (state, payerUser) => {
    const us = new UsersService();
    const user = await us.get(payerUser.id)
      .catch((err) => {
        console.log({ err });
        state.commit('CHECKOUT_ERROR');
      });
    if (user && user.data) {
      state.commit('CHECKOUT_SUCCESS');
      state.commit('SET_PAYER_USER', user.data);
    }
  },
  setInitiator: async (state, initiatorUser) => {
    const us = new UsersService();
    const user = await us.get(initiatorUser.id)
      .catch((err) => {
        console.log({ err });
        state.commit('CHECKOUT_ERROR');
      });
    if (user && user.data) {
      state.commit('CHECKOUT_SUCCESS');
      state.commit('SET_INITIATOR_USER', user.data);
    }
  },
  updateProductList: async (state, productList) => {
    state.commit('setProductList', productList);
  },
  updateShopCustomer: async (state, shopCustomer) => {
    state.commit('setShopCustomert', shopCustomer);
  },  
  updateFromShop: async (state, fromShop) => {
    state.commit('setFromShop', fromShop);
  },
  updateShopName: async (state, ShopName) => {
    state.commit('setShopName', ShopName);
  },

  updateCurrentWorkingClothStore: async (state, CurrentWorkingClothStore) => {
    state.commit('setCurrentWorkingClothStore', CurrentWorkingClothStore);
  },  

  // FOR SHOPPING LIST ShoppingList
  addToShoppingList({ commit }, item) {
    commit('ADD_TO_LIST', item);
  },
  deleteFromShoppingList({ commit }, item) {
    commit('DELETE_FROM_LIST', item);
  },
  updateInShoppingList({ commit }, item) {
    commit('UPDATE_IN_LIST', item);
  },


  deleteProduct({ commit }, sku) {
    commit('DELETE_PRODUCT', sku);
  },
  emptyProductList({ commit }) {
    commit('EMPTY_PRODUCT_LIST');
  },
  addProduct({ commit }, product) {
    commit('ADD_PRODUCT', product);
  },
  
};

export const store = new Vuex.Store({
  modules: {
    clothes,
    framings,
    states,
    users,
    scenes,
    communication,
    cmdRemotePeer,
  },
  state,
  mutations,
  actions,
  getters,
  plugins: [
    // vuexLocal.plugin
  ]
})

