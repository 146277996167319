// peer.service.js

import { Device } from 'mediasoup-client';
const socketPromise = require('./socket.io-promise').promise;

class Peer {
  constructor(socket, component) {
    const visualisationComponent= component;
    if (visualisationComponent && /(visualisation)|(videoTaking)/i.test(visualisationComponent.$options._componentTag)) {
      this.__returnVisualisationVideo = visualisationComponent.getInVideoRef();
      this.isVideoOn = visualisationComponent.getIsVideoOn();
      console.log(" :::: ==== > returnVisualisationVideo", this.__returnVisualisationVideo);
    }
    // if (visualisationComponent && /(visualisation)|(videoTaking)/i.test(visualisationComponent.$options._componentTag)) {
    //   this.__returnVisualisationVideo = visualisationComponent.getInVideoRef();
    //   this.isVideoOn = visualisationComponent.getIsVideoOn();
    //   console.log("returnVisualisationVideo", this.__returnVisualisationVideo);
    // }

    if (socket) {
      this._socket = socket;
      this._socket.request = socketPromise(this._socket)
    }

    this.stream = null;
    this.remoteStream = null;

    this.device = null;
    this.producer = null;
    this.consumer = null;
    this.dataProducer = null;
    this.dataConsumer = null;
    this.sendTransport = null;
    this.recvTransport = null;

    this.resolution = null;
    this.VIDEO_CONSTRAINS =
    {
      qvga : { width: { ideal: 320 }, height: { ideal: 240 } },
      vga  : { width: { ideal: 640 }, height: { ideal: 480 } },
      hd   : { width: { ideal: 1280 }, height: { ideal: 720 } }
    };

    // Set up socket event listeners
    this._socket.on('connect', () => {
      console.log('Connected to signaling server');
    });

    this._socket.on('mediasoup_init', async () => {
      try {
        this.device = new Device();
      } catch (error) {
        if (error.name === 'UnsupportedError') {
          console.error('browser not supported');
        }
      }
      const getRouterRtpCapabilities = await this._socket.request('getRouterRtpCapabilities');
      // console.log('================getRouterRtpCapabilities====================');
      // console.log(getRouterRtpCapabilities);
      // console.log('====================================');
      // console.log('================Type getRouterRtpCapabilities====================');
      // console.log(typeof getRouterRtpCapabilities);
      // console.log('====================================');
      if (typeof getRouterRtpCapabilities === 'object') {
        await this.device.load({ routerRtpCapabilities: getRouterRtpCapabilities });
        await this.createProducerTransport()
        await this.connectProducerTransport()
        await this.produce()
        await this.startStream()
        await this.createConsumerTransport()
        await this.connectConsumerTransport()
        await this.createDataChannel()
      } else {
        try {
          const parsedData = JSON.parse(getRouterRtpCapabilities);
          await this.device.load({ routerRtpCapabilities: parsedData });
          await this.createProducerTransport()
          await this.connectProducerTransport()
          await this.produce()
          await this.startStream()
          await this.createConsumerTransport()
          await this.connectConsumerTransport()
          await this.createDataChannel()
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      }
    });

    this._socket.on('newproducer', () => {
      console.log("New Producer")
    });

    this._socket.on('error', (error) => {
      console.error('Socket error:', error);
      // Handle socket errors, e.g., reconnect logic
    });
  }

  // Create producer transport
  async createProducerTransport() {
    const produceData = await this._socket.request('createProducerTransport', 
      {
        data:  {
          forceTcp: false,
          rtpCapabilities: this.device.rtpCapabilities,
          sctpCapabilities: this.device.sctpCapabilities,
        }
      }
   );
    if (produceData.error) {
      console.error(produceData.error);
      return;
    }
    this.sendTransport = this.device.createSendTransport(produceData);
    
  }
  // Connect producer transport
  async connectProducerTransport() {
    this.sendTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
      try {
        this._socket.request('connectProducerTransport', { dtlsParameters });
        callback()
      } catch(err) {
        errback(err);
      }
    });

  }
  // Produce event
  async produce() {
    this.sendTransport.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
      try {
        console.log('RTP Parameters:', rtpParameters);
        const { id } = await this._socket.request('produce', {
          transportId: this.sendTransport.id,
          kind,
          rtpParameters,
        });
        callback({ id });
      } catch (err) {
        errback(err);
      }
    });

    this.sendTransport.on('connectionstatechange', async (state) => {
      switch (state) {
        case 'connecting':
          console.log("Send transport is connecting..")
        break;
  
        case 'connected':
          console.log("Send transport is succesfully connected.")
        break;
  
        case 'failed':
          this.sendTransport.close()
          console.log("Send transport failed to connect")
        break;
        default: break;
      }
    });
  }

  async sendData(msg) {
    if (!this.dataProducer) {
      console.error("Data Producer not initialized !")
      return;
    }
    try {
      this.dataProducer.send(msg)
      console.log("Sending to server : ", msg)
    } catch (err) {
      console.error("Error sending data :", err)
    }
  }

  // Create consumer transport
  async createConsumerTransport() {
    const data = await this._socket.request('createConsumerTransport', {
      forceTcp: false,
      sctpCapabilities: this.device.sctpCapabilities,
    });
    if (data.error) {
      console.error(data.error);
      return;
    }
  
    this.recvTransport = this.device.createRecvTransport(data);
  }
  // Connect consumer transport
  async connectConsumerTransport() {
    this.recvTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
      try {
        this._socket.request('connectConsumerTransport', {
          transportId: this.recvTransport.id,
          dtlsParameters
        }) 
        callback();
      } catch (err) {
        errback(err);
      }
    });

    this.recvTransport.on('connectionstatechange', async (state) => {
      switch (state) {
        case 'connecting':
          console.log("Receive transport is connecting..")
          break;
  
        case 'connected':
          //await this._socket.request('resume');
          console.log("Receive transport succesfully connected.")
          break;
  
        case 'failed':
          this.recvTransport.close();
          console.log("Receive transport failed to connect")
          break;
  
        default: break;
      }
    });
  
    this.remoteStream = await this.consume(this.recvTransport);
    console.log('==================this.remoteStream==================');
    console.log(this.remoteStream);
    console.log('====================================');
    await this.startConsumingProcessedStream(this.remoteStream); //Uncomment to display remoteStream
  }

  async consume(transport) {
    const { rtpCapabilities } = this.device;
    const data = await this._socket.request('consume', { rtpCapabilities });
    const {
      producerId,
      id,
      kind,
      rtpParameters,
    } = data;
    
    let codecOptions = {};
    this.consumer = await transport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    })
    const response = await this._socket.request('resume')
    console.log("consumer : ", response)
    this.consumer.resume()
    const consumerStream = new MediaStream()
    consumerStream.addTrack(this.consumer.track)
    return consumerStream;
  }

  async startConsumingProcessedStream(processedStream) {
    if (processedStream && this.__returnVisualisationVideo) {
      this.__returnVisualisationVideo.srcObject = processedStream;
    } else {
      console.error('Cannot attach processed stream to visualisation video element');
    }
  }

  async createDataChannel() {
    this._socket.request("createDirectTransport")
    this.sendTransport.on('producedata', async({sctpStreamParameters, label, protocol}, callback, errback) => {
      try {
        const { id } = await this._socket.request("transport-producedata", {
          transportId: this.sendTransport.id,
          sctpStreamParameters,
          label,
          protocol
        });
        callback({ id });
      } catch (err) {
        errback(err);
      }
    })
    this.dataProducer = await this.sendTransport.produceData({
      sctpStreamParameters: {
        streamId: 1,
        ordered: true,
        maxPacketLifeTime: null,
        maxRetransmits: null
      },
      label: 'DC-1',
      protocol: ''
    })
    
    this.dataProducer.on('open', async () => {
      console.log("DataProducer 'open' event");
      console.log(this.dataProducer)
      this.dataProducer.send("Client connected !")
    });
  
    this.dataProducer.on('close', () => {
      console.log("DataProducer 'close' event");
    });
  
    this.dataProducer.on('error', (err) => {
      console.error("DataProducer 'error' event:", err);
    });
    const data = await this._socket.request('transport-consumedata', { dataProducerId : this.dataProducer.id});
    const {
      id,
      dataProducerId,
      sctpParameters,
      label,
      protocol
    } = data;
    this.dataConsumer = await this.recvTransport.consumeData({
      id,
      dataProducerId,
      sctpStreamParameters: sctpParameters,
      label,
      protocol
    })
    this.dataConsumer.on('open', () => {
      console.log(this.dataConsumer)
      console.log("DataConsumer 'open' event");
    });
  
    this.dataConsumer.on("message", (message, ppid) => {
      console.log(message)
      if (ppid === 51)
        console.log("text message received:", message.toString("utf-8"));
      else if (ppid === 53)
        console.log("binary message received");
    });

    this.dataConsumer.on('close', () => {
      console.log("DataConsumer 'close' event");
    });
  
    this.dataConsumer.on('error', (err) => {
      console.error("DataConsumer 'error' event:", err);
    });
  }

  async startStream() {
    try {
      if (!this.device.canProduce('video')) {
        console.error('cannot produce video');
        return;
      }
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({ video: true });
      } catch (err) {
        console.error('getUserMedia() failed:', err.message);
        throw err;
      }
      const track = this.stream.getVideoTracks()[0];
      if (this.__returnVisualisationVideo && track) {
        this.__returnVisualisationVideo.srcObject = new MediaStream([track]);
      } else {
        console.error('Cannot attach video track to visualisation video element');
      }
      /*if ($chkSimulcast.checked) {
        params.encodings = [
          { maxBitrate: 100000 },
          { maxBitrate: 300000 },
          { maxBitrate: 900000 },
        ];
        params.codecOptions = {
          videoGoogleStartBitrate : 1000
        };
      }*/
      this.producer = await this.sendTransport.produce({track:track});
    } catch (err) {
      console.log("Error can't start stream : ", err)
    }
  }
}

export default Peer;
